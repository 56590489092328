<template>
	<div class="app" v-if="userAuthenticated">
		<div class="app-content">
			<slot />
		</div>

		<chat-launcher />
	</div>
</template>

<script>
import AuthService from "@/services/Auth.service.js";
import ChatLauncher from "./components/chat-launcher/zendesk.vue";
import EventBus from "@/eventbus.js";

export default {
	name: "FullScreenApp",

	data() {
		return {
			userAuthenticated: false,
			user: null
		};
	},

	async mounted() {
		try {
			// this is a hack to avoid showing anything that would "crash" because user is not logged in
			this.user = await AuthService.getUserInfo();
			this.$i18n.locale = this.user.language;
			EventBus.$emit("changedLanguage", this.$i18n.locale);
			this.$dayjsSetLocale(this.$i18n.locale);

			this.userAuthenticated = true;
			// init session tracker
			this.$initKeepSession();
		} catch (error) {
			this.$log.error(
				"Ops! You're not logged in, sorry! You'll be redirected to login page in a few seconds!",
				error
			);
		}
	},

	components: {
		ChatLauncher
	}
};
</script>

<style lang="scss" scoped>
.app {
	overflow: hidden;
	display: flex;
	height: 100vh;
	background-color: $background;

	.sidebar {
		display: flex;
		flex-shrink: 0;
	}

	.app-content {
		background-color: $background;
		flex: 1 1 0%;
		overflow-x: auto;
		overflow-y: scroll;

		&:focus {
			outline: 2px solid transparent;
			outline-offset: 2px;
		}
	}
}
</style>
