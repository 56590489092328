<template>
	<menu-dropdown ref="menuDropdown">
		<template #icon>
			<ws-icon icon="question" size="lg" />
		</template>

		<chat />

		<a
			href="https://api.wattsense.com"
			rel="noopener"
			target="_blank"
			class="navbar-item"
		>
			<span>
				{{ $t("api-documentation") }}
			</span>
			<span class="icon">
				<ws-icon icon="external-link-alt" size="sm" />
			</span>
		</a>
		<a
			:href="
				!isSiemens
					? 'https://support.wattsense.com/portal/en/home'
					: 'https://sid.siemens.com/home'
			"
			rel="noopener"
			target="_blank"
			class="navbar-item flex"
		>
			<span>
				{{ $t("support-forum") }}
			</span>
			<span class="icon">
				<ws-icon icon="external-link-alt" size="sm" />
			</span>
		</a>
		<a
			v-if="!isSiemens"
			href="https://wattsense.com/resources"
			rel="noopener"
			target="_blank"
			class="navbar-item"
		>
			<span>
				{{ $t("resources") }}
			</span>
			<span class="icon">
				<ws-icon icon="external-link-alt" size="sm" />
			</span>
		</a>
		<a
			v-if="isSiemens"
			href="https://new.siemens.com/global/en/products/buildings/automation/cloud-solutions/terms.html"
			rel="noopener"
			target="_blank"
			class="navbar-item"
		>
			<span> Legal and data privacy </span>
			<span class="icon">
				<ws-icon icon="external-link-alt" size="sm" />
			</span>
		</a>
		<div class="is-flex version">
			<div class="is-size-7 has-text-grey">
				{{ version }}
			</div>
		</div>
	</menu-dropdown>
</template>

<script>
import MenuDropdown from "./components/menu-dropdown.vue";
import Chat from "./components/chat.vue";

export default {
	name: "HelpResources",

	data() {
		return {
			backendVersion: null
		};
	},

	computed: {
		isSiemens() {
			return this.$clientName === "siemens";
		},

		version() {
			return `v${import.meta.env.VERSION || ""}`;
		}
	},

	components: {
		MenuDropdown,
		Chat
	}
};
</script>

<style lang="scss" scoped>
.version {
	justify-content: flex-end;
	flex-direction: column;

	text-align: right;
}
a {
	display: flex;
	justify-content: space-between;
	.icon {
		margin-left: 5px;
	}
}
</style>
