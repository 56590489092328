import AppLayout from "./App.vue";
import AuthLayout from "./Auth.vue";
import PlainHtml from "./PlainHtml.vue";
import FullScreen from "./FullScreen.vue";

export default {
	install(app) {
		app.component("AppLayout", AppLayout);
		app.component("AuthLayout", AuthLayout);
		app.component("PlainHtmlLayout", PlainHtml);
		app.component("FullScreenLayout", FullScreen);
	}
};
