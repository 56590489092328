<template>
	<ws-button
		is-small
		@click="toggleExpansion"
		:aria-label="$t('expand-collapse')"
	>
		<template #icon>
			<ws-icon :icon="icon" size="sm" />
		</template>
	</ws-button>
</template>

<script setup>
import { computed, inject } from "vue";

const { expanded, toggleExpansion } = inject("expansion");

const icon = computed(() =>
	expanded.value ? "double-chevron-left" : "double-chevron-right"
);
</script>

<style lang="scss" scoped>
button {
	color: $grey;
	border-color: $grey;
	background-color: transparent;
	transition-property: backgrond-color, border-color;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;

	&:hover {
		border-color: $color-grey-700;
		color: $color-grey-700;
		background-color: $white;
	}

	&:focus,
	&.is-focused {
		box-shadow: none;
		border-color: $color-grey-300;
	}
}
</style>
