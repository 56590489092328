export default [
	{
		path: "/gateway-simulator",
		name: "gateway-simulator",
		component: () => import("./simulator.vue"),
		meta: {
			requiresAuth: true
		}
	}
];
