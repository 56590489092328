import AuthService from "../Auth.service.js";
import axios from "axios";
import defaultLorawanProperties from "@/views/box/views/configuration/views/properties/helpers/default-lorawan-properties.js";

const API = import.meta.env.VITE_API;
const CONFIG_WIZARD_API = import.meta.env.VITE_CONFIGURATION_WIZARD_API_NEW;

function assignUserLanguageToResponseData(data, lang) {
	return data.map((item) => {
		if (item.titles) {
			item.title = item.titles[lang] || item.name || item.title;
			item.name = item.titles[lang] || item.name || item.title;
		}
		if (item.descriptions) {
			item.description = item.descriptions[lang] || item.description;
		}
		return item;
	});
}

export default {
	async requestNewEquipment(info) {
		return axios
			.post(`${API}/public/requestEquipment`, {
				info: info,
				name:
					AuthService.user().fullName ??
					AuthService.user().username.split("@")[0],
				user: AuthService.user().username
			})
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getAllEquipment(deviceSubscription = null) {
		return axios
			.get(`${CONFIG_WIZARD_API}/equipment`, {
				headers: {
					"X-Package": deviceSubscription
				}
			})
			.then((res) => {
				// if equipment has multilanguage, get the language from the headers and assign equipment name #WRD-995
				const lang =
					(["en", "fr", "de"].includes(
						res.config.headers["Accept-Language"]
					) &&
						res.config.headers["Accept-Language"]) ||
					"en";
				const finalEquipment = assignUserLanguageToResponseData(
					res.data,
					lang
				);
				return [...finalEquipment];
			})
			.catch((err) => {
				throw err.response;
			});
	},

	async getEquipmentInfo(equipmentId) {
		return axios
			.get(`${CONFIG_WIZARD_API}/equipment/${equipmentId}`)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getEquipmentProtocolProperties(equipmentId, protocol, page = 1) {
		return axios
			.get(
				`${CONFIG_WIZARD_API}/equipment/${equipmentId}/${protocol}/properties?page=${page}`
			)
			.then((res) => {
				// if property has multilanguage, get the language from the headers and assign property name #WRD-995
				const lang =
					(["en", "fr", "de"].includes(
						res.config.headers["Accept-Language"]
					) &&
						res.config.headers["Accept-Language"]) ||
					"en";
				const finalProps = assignUserLanguageToResponseData(
					res.data.data,
					lang
				);

				return {
					...res.data,
					data: finalProps
				};
			})
			.catch((err) => {
				throw err.response;
			});
	},

	cacheEquipmentProtocolConfiguration: {},
	async getEquipmentProtocolConfiguration(equipmentId, protocol) {
		const key = `${equipmentId}-${protocol}`;
		if (this.cacheEquipmentProtocolConfiguration[key]) {
			return Promise.resolve(
				this.cacheEquipmentProtocolConfiguration[key]
			);
		}
		return axios
			.get(`${CONFIG_WIZARD_API}/equipment/${equipmentId}/${protocol}`)
			.then((res) => {
				this.cacheEquipmentProtocolConfiguration[key] = res.data;
				return Promise.resolve(
					this.cacheEquipmentProtocolConfiguration[key]
				);
			})
			.catch((err) => {
				throw err.response;
			});
	},

	lorawanCodecs: {},
	async getAvailableLorawanCodecs() {
		if (Object.keys(this.lorawanCodecs).length > 0) {
			return Promise.resolve(this.lorawanCodecs);
		}

		return axios
			.get(`${CONFIG_WIZARD_API}/lorawanCodecIds`)
			.then((res) => {
				this.lorawanCodecs = res.data;
				return this.lorawanCodecs;
			})
			.catch((err) => {
				throw err.response;
			});
	},

	/**
	 * DEPRECATED
	 * @param {} codecId
	 * @returns
	 */
	async getLorawanPropertiesFromCodec(codecId = null) {
		// if it's null codec, return with default properties from lorawan
		if (codecId === null) {
			return Promise.resolve(Object.values(defaultLorawanProperties));
		}

		return axios
			.get(`${CONFIG_WIZARD_API}/lorawanPropertiesFromCodec/${codecId}`)
			.then((res) => {
				const properties = res.data;
				// ALL lorawan properties should have default system codecs, so if it's not present in the response, add them
				const defaultCodecs = Object.values(defaultLorawanProperties);
				defaultCodecs.forEach((codec) => {
					if (
						!properties.find(
							(c) => c.codecPropertyId === codec.codecPropertyId
						)
					) {
						properties.push(codec);
					}
				});
				return properties;
			})
			.catch((err) => {
				throw err.response;
			});
	},

	async getEquipmentProperties(equipmentId = null) {
		return axios
			.get(`${CONFIG_WIZARD_API}/equipment/${equipmentId}/properties`)
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				throw err.response;
			});
	},

	/**
	 *
	 * @param {{ equipmentId: String, wEquipentId: String, wPropertyId: String, override: Object }[]} data
	 * @param {String} deviceId
	 * @returns
	 */
	async createBulkWizardProperties(data, deviceId) {
		return axios
			.post(
				`${API}/devices/${deviceId}/configs/draft/bulkWizardProperties`,
				data
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	/**
	 *
	 * @param {{ equipmentId: String, wEquipentId: String, wPropertyId: String, override: Object }[]} data
	 * @param {String} deviceId
	 * @returns
	 */
	async createBulkWizardProperty(data, deviceId) {
		return axios
			.post(
				`${API}/devices/${deviceId}/configs/draft/wizardProperty`,
				data
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	}
};
